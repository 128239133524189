import Image from 'next/image'
import { useAnalytics } from '../../lib/state'

export const HomeHero = () => {
  const analytics = useAnalytics()

  return (
    <div className='home-hero'>
      <div className='home-hero__container'>
        <div className='home-hero__img'>
          <Image
            layout={'fill'}
            objectFit={'cover'}
            objectPosition={'50% 0'}
            src={'/assets/img/home-hero-compass-v3@2x.jpg'}
            priority
            alt='' />
        </div>
        <div className='home-hero__details'>
          <div className='home-hero__details-body'>
            <h1 className='home-hero__details-body-title'>Move your health and fitness career further</h1>
            <p>Navigate your career and achieve success as an ACE<sup>&reg;</sup> Certified Pro. Through exclusively curated content, resources and Challenges, based on your interests and goals, ACE<sup>&reg;</sup> Pro Compass will point you in the right direction toward defining, developing and reaching your potential.</p>
            <div className='home-hero__details-body-actions'>
              <a
                className='home-hero__details-body-link'
                href={process.env.NEXT_PUBLIC_AUTH_URL}
                onClick={() => {
                  analytics('link', {
                    'event_type': 'get_started',
                  })
                }}>
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
