import type { NextPage } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { Loading } from '../components/common'
import { HomeBillboard, HomeHero, HomeProcess, HomeStart } from '../components/home'
import { useAnalyticsPageView, useAuth } from '../lib/state'

const Home: NextPage = () => {
  const router = useRouter()
  const auth = useAuth()

  useEffect(() => {
    localStorage.removeItem('ace-compass-auth-route')
    if (auth) router.replace('/my-compass')
  }, [auth])

  useAnalyticsPageView({ title: 'Home', type: 'Home' })

  if (auth === null) return (
    <div className='loading loading--page'>
      <Loading />
    </div>
  )

  return (
    <>
      <Head>
        <title>ACE Compass</title>
        <meta name='description' content='' />
      </Head>
      <HomeHero />
      <HomeProcess />
      <HomeBillboard />
      <HomeStart />
    </>
  )
}

export default Home
