interface Props {
  index: number;
  width?: number
  height?: number
  classNames?: string
}

export const TrailSegment = ({ index, width = 367, height = 285 , classNames = 'trail-segment' }: Props) => {

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 367 285"
      className={classNames}
    >
      <defs>
        <path
          id={"trail-path-" + index}
          d="M1.682 1.829C33.142 64.793 88.582 102.516 168 115c119.127 18.726 197 115.1 197 175"
        ></path>
        <mask id={"trail-mask-" + index}>
          <use
            className="trail-segment__mask"
            xlinkHref={"#trail-path-" + index}
          />
        </mask>
      </defs>
      <use
        className="trail-segment__path"
        xlinkHref={"#trail-path-" + index}
        mask={"url(#trail-mask-" + index + ")"}
      />
    </svg>
  );
}
