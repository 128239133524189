import { MediaIcon, PathIcon, SummitIcon, TargetIcon } from '../icons'

interface Props {
  index: number;
  data: StartFeature;
}

export interface StartFeature {
  icon: 'media' | 'path' | 'summit' | 'target'
  title: string
  description: string
}

export const HomeStartFeature = (props: Props) => {
  // Determine component icon
  const featureIcon = (iconName) => {
    switch (iconName) {
      case 'media': return <MediaIcon width={85} height={84} classNames='home-process-step__icon' />
      case 'path': return <PathIcon width={116} height={66} classNames='home-process-step__icon' />
      case 'summit': return <SummitIcon width={121} height={75} classNames='home-process-step__icon' />
      case 'target': return <TargetIcon width={70} height={80} classNames='home-process-step__icon' />
      default: return <MediaIcon width={85} height={84} classNames='home-process-step__icon' />
    }
  }

  return (
    <div className='home-start-feature'>
      <div
        className='home-start-feature__container'
      >
        {featureIcon(props.data.icon)}
        <div className='home-start-feature__details'>
          <h3 className='home-start-feature__details-title'>{props.data.title}</h3>
          <p dangerouslySetInnerHTML={{ __html: props.data.description }}></p>
        </div>
      </div>
    </div>
  )
}
