import Image from 'next/image'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { TrailSegment, TrailShort, TrailStart } from '.'
import { CrosshairIcon, SignIcon, SlidersIcon } from '../icons'

interface Props {
  index: number;
  stepData: ProcessStep;
}

export interface ProcessStep {
  icon: 'crosshair' | 'sign' | 'sliders'
  title: string
  description: string,
  imageSrc: string,
  imageWidth: number,
  imageHeight: number,
  contentClassModifier: string,
}

export const HomeProcessStep = (props: Props) => {
  const [animClass, setAnimClass] = useState('')
  const [mobile, setMobile] = useState(undefined)

  // Detect small screen sizes, via https://stackoverflow.com/a/66182806
  useEffect(() => {
    const updateMobile = () => {
      setMobile(window.innerWidth < 960 ? true : false)
    }

    updateMobile()
    window.addEventListener('resize', updateMobile)

    return () => {
      window.removeEventListener('resize', updateMobile)
    }
  }, [])

  // Determine component trail
  const trailType = () => {
    if (mobile) {
      return <TrailShort classNames={animClass} index={props.index} />
    } else {
      if (props.index === 0) {
        return <TrailStart classNames={animClass} index={props.index} />
      } else {
        return <TrailSegment classNames={animClass} index={props.index} />
      }
    }
  }

  // Determine component icon
  const stepIcon = (iconName) => {
    switch (iconName) {
      case 'crosshair': return <CrosshairIcon width={88} height={88} classNames='home-process-step__icon' />
      case 'sign': return <SignIcon width={88} height={88} classNames='home-process-step__icon' />
      case 'sliders': return <SlidersIcon width={88} height={78} classNames='home-process-step__icon' />
      default: return <SignIcon width={88} height={88} classNames='home-process-step__icon' />
    }
  }

  // Determine "step" CSS class
  const altClass = () => {
    if (props.index === 0) {
      return ' home-process-step--first';
    } else if (props.index % 2 != 0) {
      return ' home-process-step--alt';
    } else {
      return '';
    }
  }

  return (
    <div className={'home-process-step' + altClass()}>
      <motion.div
        viewport={{ margin: '0px 0px -25% 0px', once: true }}
        onViewportEnter={() => {setAnimClass('trail-segment trail-segment--animated')}}
        className="home-process-step__trail"
      >
        {trailType()}
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ scale: [0.5, 1.15, 1], opacity: [0, 1] }}
        viewport={{ margin: '0px 0px -25% 0px', once: true }}
        transition={{ duration: 0.5, type: 'spring', delay: 0.25 }}
        className='home-process-step__container'
      >
        <div className={`home-process-step__content home-process-step__content--${props.stepData.contentClassModifier}`}>

          <div className="home-process-step__content-caption">
            {stepIcon(props.stepData.icon)}
            <div className='home-process-step__details'>
              <h3 className='home-process-step__details-title'>{props.stepData.title}</h3>
              <p dangerouslySetInnerHTML={{ __html: props.stepData.description }}></p>
            </div>
          </div>

          <div className="home-process-step__content-figure">
            <Image
                className='home-process-step__content-figure-img'
                layout={'responsive'}
                width={props.stepData.imageWidth}
                height={props.stepData.imageHeight}
                src={props.stepData.imageSrc}
                alt='' />
          </div>

        </div>
      </motion.div>
    </div>
  )
}
