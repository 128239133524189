import Image from 'next/image'
import { HomeProcessStep, ProcessStep } from '.'

const processSteps: Array<ProcessStep> = [
  {
    icon: 'crosshair',
    title: 'Set your coordinates',
    description: 'Start by telling us about your certification(s), experience and goals. ACE<sup>&reg;</sup> Pro Compass will map out a unique pathway that provides you curated content to help you grow and succeed in your career.',
    imageSrc: '/assets/img/home-onboarding@2x.png',
    imageWidth: 576,
    imageHeight: 376,
    contentClassModifier: 'onboarding',
  },
  {
    icon: 'sign',
    title: 'Embark on your journey',
    description: 'Discover and engage with relevant articles, resources and Challenges that have been carefully selected for the path you\'re on. The more you complete, the more knowledgeable you become.',
    imageSrc: '/assets/img/home-cards@2x.png',
    imageWidth: 641,
    imageHeight: 507,
    contentClassModifier: 'cards',
  },
  {
    icon: 'sliders',
    title: 'Recalibrate your Compass',
    description: 'Share your feedback on the content you consume to make your future recommendations even more relevant over time. You\'ll have exactly what you need to achieve success.',
    imageSrc: '/assets/img/home-feedback@2x.png',
    imageWidth: 576,
    imageHeight: 376,
    contentClassModifier: 'feedback',
  }
]

export const HomeProcess = () => {
  return (
    <div className='home-process bg-mtn bg-mtn--valign-middle'>
      <div className='home-process__container'>
        <div className='home-process__intro'>
          <div className='home-process__intro-img'>
            <Image
              className='home-process__intro-icon'
              layout={'fixed'}
              width={20}
              height={80}
              src={'/assets/img/nav-needle.svg'}
              alt='' />
          </div>
          <h2 className='home-process__intro-title'>How it works</h2>
          <p>ACE<sup>&reg;</sup> Pro Compass takes your interests, goals and feedback to calibrate individualized content that will guide you on the career path you seek.</p>
        </div>
        {processSteps.map((item, index) => (
          <HomeProcessStep
            key={index}
            index={index}
            stepData={processSteps[index]}
          />
        ))}
      </div>
    </div>
  )
}
