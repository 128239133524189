interface Props {
  index: number;
  width?: number
  height?: number
  classNames?: string
}

export const TrailStart = ({ index, width = 261, height = 347 , classNames = 'trail-segment' }: Props) => {

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 261 347"
      className={classNames}
    >
      <defs>
        <path
          id={"trail-path-" + index}
          d="M259 1.575c0 77.141-39.89 134.056-119.668 170.743C59.553 209.005 13.442 269.424 1 353.575"
        ></path>
        <mask id={"trail-mask-" + index}>
          <use
            className="trail-segment__mask"
            xlinkHref={"#trail-path-" + index}
          />
        </mask>
      </defs>
      <use
        className="trail-segment__path"
        xlinkHref={"#trail-path-" + index}
        mask={"url(#trail-mask-" + index + ")"}
      />
    </svg>
  );
}
