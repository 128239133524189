interface Props {
  index: number;
  width?: number
  height?: number
  classNames?: string
}

export const TrailShort = ({ index, width = 130, height = 195 , classNames = 'trail-segment' }: Props) => {

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 130 195"
      className={classNames}
    >
      <defs>
        <path
          id={"trail-path-" + index}
          d="M2 2c3.201 39.738 24.24 68.601 63.115 86.592 58.313 26.985 63.203 104.237 63.203 104.237"
        ></path>
        <mask id={"trail-mask-" + index}>
          <use
            className="trail-segment__mask"
            xlinkHref={"#trail-path-" + index}
          />
        </mask>
      </defs>
      <use
        className="trail-segment__path"
        xlinkHref={"#trail-path-" + index}
        mask={"url(#trail-mask-" + index + ")"}
      />
    </svg>
  );
}
