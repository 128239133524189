import { motion } from 'framer-motion'
import { HomeStartFeature, StartFeature } from '.'
import { useAnalytics } from '../../lib/state'

const startFeatures: Array<StartFeature> = [
  {
    icon: 'media',
    title: 'Gain knowledge',
    description: 'with career-related videos, articles, talks and more.',
  },
  {
    icon: 'target',
    title: 'Discover content',
    description: 'with recommendations that are tailored just for you.',
  },
  {
    icon: 'summit',
    title: 'Conquer challenges',
    description: 'that make learning fun and deliver immediate results.',
  },
  {
    icon: 'path',
    title: 'Track progress',
    description: 'to see how far you\’ve come in your career journey and be inspired to go further.',
  }
]

const motionRow = {
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
      staggerChildren: 0.2
    }
  },
  hidden: {
    opacity: 0
  }
}

const motionFeature = {
  visible: {
    y: 0,
    transition: {
      duration: 0.5
    }
  },
  hidden: {
    y: 300
  }
}

export const HomeStart = () => {
  const analytics = useAnalytics()

  return (
    <div className='home-start bg-mtn bg-mtn--valign-bottom'>
      <div className='home-start__container'>
        <div className='home-start__intro'>
          <h2 className='home-start__intro-title'>Join ACE<sup>&reg;</sup> Pro Compass to:</h2>
        </div>
        <motion.div
          className='home-start__feature-row'
          initial='hidden'
          whileInView='visible'
          viewport={{ margin: '0px 0px -50% 0px', once: true }}
          variants={motionRow}
        >
          {startFeatures.map((item, index) => (
            <motion.div
              className='home-start__feature-column'
              style={{ paddingTop: index * 100 + 'px' }}
              key={index}
              variants={motionFeature}
            >
              <HomeStartFeature
                key={index}
                index={index}
                data={startFeatures[index]}
              />
            </motion.div>
          ))}
        </motion.div>
        <div className='home-start__actions'>
          <a
            className='home-start__actions-link'
            href={process.env.NEXT_PUBLIC_AUTH_URL}
            onClick={() => {
              analytics('link', {
                'event_type': 'get_started',
              })
            }}>
            Get Started
          </a>
        </div>
      </div>
    </div>
  )
}
